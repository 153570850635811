import axios from "axios"

export function getCorrelations(moduleName: string) {
    return axios.get("/api/categories/lessons", { params: { module: moduleName } }).then(({ data }) => data) as Promise<Array<{
        module: string
        type: string
        number: number
        categoryId: string
        topicId: string
        subtopicId: string
    }>>
}
// todo: STEM-1117 move to diff file
export function addCorrelation(body): Promise<any> {
    return axios.post(`/api/editing/categories/lesson-correlation`,body).then((res) => res.data)
}
export function deleteCorrelations(correlations: any) {
    return axios.delete("/api/categories/lessons", {data: correlations}).then((res) => res)
}

export interface CombinedReturn {
    [key: string]: {
        imageUrl: string,
        units: {
            [key: string]: {
                sections: {
                    [key: string]: {
                        lessons: {
                            title: string
                            lessonId: string
                            categoryID: string
                        }[]
                    }
                }
            }
        }
    }
}

export type GetStandardsReturn = {
    standards: Standard[]
}

export type Standard = {
    id: string
    standard: string
    index: number
}

export type StandardsReturn = {
    standard: string
    topics: {
        topic: string
        subtopics: Subtopic[]
    }[]
}

export type Subtopic = {
    id: string
    standard: string
    topic: string
    subtopic: string
    index: number
    description: string
}

export function getCombinedCategories(): Promise<CombinedReturn> {
    return axios.get("/api/categories/combined")
        .then((response) => response.data)
}

export async function getStandards(): Promise<Standard[]> {
    const response = await axios.get("/api/standards") as { data: { standards: Standard[] } }
    return response.data.standards
}

export async function getSubtopics(): Promise<StandardsReturn[]> {
    const response = await axios.get("/api/standards/subtopics") as { data: Subtopic[] }
    if (!response.data) return [{standard: "ERROR", topics: []}]
    const standards : string[] = []
    response.data.forEach(s => {
        if (!standards.includes(s.standard)) standards.push(s.standard)
    })
    standards.sort()
    const standardsReturn: StandardsReturn[] = []
    standards.forEach(s => {
        standardsReturn.push({ standard: s, topics: [] })
    })
    response.data.forEach(s => {
        const standard = standardsReturn.find(sr => sr.standard === s.standard)
        if (standard) {
            const topic = standard.topics.find(t => t.topic === s.topic)
            if (topic) {
                topic.subtopics.push(s)
            } else {
                standard.topics.push({ topic: s.topic, subtopics: [s] })
            }
        }
    })
    standardsReturn.forEach(sr => {
        sr.topics.sort((a, b) => a.topic.localeCompare(b.topic))
        sr.topics.forEach(t => {
            t.subtopics.sort((a, b) => a.subtopic.localeCompare(b.subtopic))
        })
    })
    return standardsReturn
}

export async function getGradeLevels() {
    const response = await axios.get("/api/grade-levels") as { data: { gradeLevels: string[]}}
    return response.data.gradeLevels
}
