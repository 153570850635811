import { SubmissionResponse } from "shared/types/moduleTypes"
import DrawingImageButton from "../components/DrawingImageButton"
import styles from "../submissionTeacher.module.scss"
import Pane from "components/Dashboards/General/Pane"
import { Image } from "react-bootstrap"

interface Props {
    index: number
    response: SubmissionResponse
    onGrade: (questionNumber: number, grade: "correct" | "incorrect") => void
}

const AssessmentDrawingResponseItem = ({index, response, onGrade} : Props) => {

  const correct = response.isCorrect
  const showCorrectIncorrect = !!(response.answer || response.drawingResponse)
  const showExampleDrawing = response.exampleDrawing || response.correctAnswer

  return (
    <Pane className="mb-4 shadow-sm">
      <span className={"pe-2 " + (showCorrectIncorrect ? (correct ? "text-success" : "text-danger"): "text-secondary")}>
        <span className="fw-bold">{response.questionNumber ?? (index + 1)}.</span> <span dangerouslySetInnerHTML={{ __html: response.question }} />
        {showCorrectIncorrect && <i className={`fas ${correct ? `fa-check text-success` : `fa-times text-danger`} ms-2`} />}
      </span>
      {response.drawingResponse && <div>
        <p className="fw-bold mt-2">Student's drawing answer: </p>
        <Image src={response.drawingResponse} alt={'Student Drawing Answer'} className="shadow-sm border border-1 rounded mb-2" fluid/>
      </div>}
      {response.drawingResponse && <div className="mt-4 d-flex">
          <span 
              className="me-4 shadow-sm border border-1 p-2 rounded" role="button" 
              onClick={() => onGrade(response.questionNumber, "correct")}>
              <span className="me-2 d-none d-md-inline">Mark Correct</span>
              <i
                  className={`fas fa-check ${styles.correctIcon}`}
              />
          </span>
          <span
              className="shadow-sm border border-1 p-2 rounded" 
              role="button" 
              onClick={() => onGrade(response.questionNumber, "incorrect")}>
              <span className="me-2 d-none d-md-inline">Mark Incorrect</span>
              <i
                  className={`fas fa-times ${styles.incorrectIcon}`}
              />
          </span>
      </div>}
      {showExampleDrawing && 
          <div className="mt-3">
            <DrawingImageButton 
              drawingUrl={response?.exampleDrawing}
              title="Example Drawing"
              optionalAnswer={response?.correctAnswer} />
          </div>}
    </Pane>
  )
}

export default AssessmentDrawingResponseItem
