import { ReactTable } from "@stem-sims/nexus"
import React from "react"
import { useSearchParams } from "react-router-dom"
import { AccountInfo, getAccountInfo } from "shared/routes/account/info"
import { getStudents } from "shared/routes/teacher"
import "./printCodes.module.scss"

export default function PrintCodes() {
    const [ searchParams ] = useSearchParams()
    const classId = searchParams.get("classId")

    const [ data, setData ] = React.useState<{ id: string, name: string, code: string}[]>(null)

    React.useEffect(() => {
        getStudents({ classID: classId }).then((students) => {
            setData(students.map((student) => ({
                code: student.studentCode,
                id: student.uuid,
                name: student.name
            })))
        })
    }, [classId])

    const [ info, setInfo ] = React.useState<AccountInfo>(null)

    React.useEffect(() => {
        getAccountInfo().then((info) => setInfo(info))
    }, [classId])

    React.useEffect(() => {
        if (!data || !info) return

        window.print()
        window.close()
    }, [data, info])

    const columns = [
        {
            Header: "Student Name",
            accessor: "name",
            disableSortBy: true,
        },
        {
            Header: "Login Code",
            accessor: "code",
            disableSortBy: true,
        }
    ]


    if (!data || !info) {
        return null
    }

    return <>
        <div className="break-margin" />
        <h2>Student Login Codes</h2>
        <h3>Username: {info.username}</h3>
        <div className="text-start">
            <ReactTable
                columns={columns}
                data={data}
                bulkActions={[]}
                hideFilter
                hidePagination
            />
        </div>
    </>
}
