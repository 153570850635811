import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import BeatLoader from 'react-spinners/BeatLoader'
import { ClassResponse, StudentResponse } from '../../../shared/types/teacherTypes'
import * as teacher from '../../../shared/routes/teacher'
import { useModel } from '@stem-sims/nexus'
import GradeBookTable from './GradeBookTable'

interface GradeBookProps {
    activeClass: ClassResponse
    refreshClasses: () => void
}

export default function GradeBook({ activeClass, refreshClasses }: GradeBookProps) {
    const [students, setStudents] = useState<StudentResponse[]>([])
    const [loadingStudents, setLoading] = useState(false)

    const sort = React.useRef<"first" | "last">(localStorage.getItem("studentSortOrder") as "first" | "last" ?? "last")

    const studentSort = (s1: StudentResponse, s2: StudentResponse) => {
        const firstStudentSort = s1.name.split(" ").at(sort.current === "first" ? 0 : -1)
        const secondStudentSort = s2.name.split(" ").at(sort.current === "first" ? 0 : -1)
        return firstStudentSort.localeCompare(secondStudentSort)
    }

    const refreshStudents = () => {
        setLoading(true)
        return teacher.getStudents({ classID: activeClass.id }).then((teacherStudents) => {
            setStudents(teacherStudents.sort(studentSort))
            setLoading(false)
        }).catch(() => {
            //Does the class still exist?
            toast.dismiss()
            toast.error("There was an issue getting your students.")
            refreshClasses()
        })
    }

    function assignmentError(err) {
        toast.error(err.response?.data?.message ?? "There has been an error loading the assignments. Please try again.")
        refreshClasses()
    }

    const { response: assignments, loading, refreshModel: reloadAssignments } = useModel({
        model: teacher.getAssignments,
        onError: assignmentError,
        props: { classID: activeClass.id }
    })

    useEffect(() => {
        reloadAssignments()
    },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeClass.id])

    useEffect(() => {
        void refreshStudents()
    },
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [activeClass.id])

    if (loading || loadingStudents) {
        return (
            <div className="d-table h-100 mx-auto">
                <div className="d-table-cell text-center align-middle">
                    <BeatLoader size={15} />
                </div>
            </div>
        )
    }

    return (
        <div className="mx-2">
            <h1 className="h2 text-start mt-3">Class Grades</h1>
            <GradeBookTable assignments={assignments} students={students} />
        </div>
    )
}
