import { Button, Modal } from "react-bootstrap"
import useBoolean from "helpers/useBoolean"
import styles from "../submissionTeacher.module.scss"

interface Props {
    drawingUrl: string
    title: string
    optionalAnswer?: string
}

const DrawingImageButton = ({drawingUrl, title, optionalAnswer} : Props) => {
    const [showModal, toggleModal] = useBoolean(false)
    return (
        <div>
            <Button variant="theme" onClick={toggleModal}>{title}</Button>
            <Modal show={showModal} onHide={toggleModal} size={"xl"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {drawingUrl && 
                        <img className={styles.tableAnswer} src={drawingUrl} alt="Example Drawing Answer" />}
                    {optionalAnswer && <h3 dangerouslySetInnerHTML={{ __html: optionalAnswer}} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DrawingImageButton
