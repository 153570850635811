import axios from "axios"

export interface AccountInfo {
    username: string
}

export async function getAccountInfo(): Promise<AccountInfo> {
    const response = await axios.get("/api/account/info")
    return response.data
}

const info = {
    getAccountInfo,
}

export default info
