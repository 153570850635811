import React from "react"
import { useSearchParams } from "react-router-dom"
import { AccountInfo, getAccountInfo } from "shared/routes/account/info"
import { getStudents } from "shared/routes/teacher"

export default function PrintLoginInstructions() {
    const [ searchParams ] = useSearchParams()
    const classId = searchParams.get("classId")

    const [ data, setData ] = React.useState<{ id: string, name: string, code: string}[]>(null)

    React.useEffect(() => {
        getStudents({ classID: classId }).then((students) => {
            setData(students.map((student) => ({
                code: student.studentCode,
                id: student.uuid,
                name: student.name
            })))
        })
    }, [classId])

    const [ info, setInfo ] = React.useState<AccountInfo>(null)

    React.useEffect(() => {
        getAccountInfo().then((info) => setInfo(info))
    }, [classId])

    React.useEffect(() => {
        if (!data || !info) return

        window.print()
        window.close()
    }, [data, info])

    if (!data || !info) {
        return null
    }

    return <>
        {data.map((student) => (<>
            <h3 style={{paddingTop: "60px"}}>Login instructions for {student.name}</h3>
            <div className="text-start">
                <ol>
                    <li>Go to https://stemsims.com</li>
                    <li>Click on the "Log In" button in the upper right corner</li>
                    <li>Enter the following credentials:</li>
                    <ul>
                        <li>Username: {info.username}</li>
                        <li>Student Code: {student.code}</li>
                    </ul>
                    <li>Click "Login"</li>
                </ol>
            </div>
            <div style={{breakAfter: "page"}}></div>
        </>))}
    </>
}
