import CustomHTML from 'components/CustomHTML'
import  { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Question } from 'shared/lessons'
import DrawingQuestion from './DrawingQuestion'
import FullScreenWrapper from './FullScreenWrapper'
import styles from "./DrawingQuestion.module.scss"

interface Props {
    lessonQuestion: Question
    questionIndex: string
    sectionNumber: number // only for lesson question
    autoSave?: () => void
    stopDraggable: () => void
}

const OpenCanvasButton = ({ lessonQuestion, questionIndex, sectionNumber, autoSave, stopDraggable} : Props) => {
    const [openCanvas, setOpenCanvas] = useState<boolean>(false)

    const onOpen = () => {
        setOpenCanvas(true)
        stopDraggable()
    }
    const onMinimize = () => {
        setOpenCanvas(false)
        stopDraggable()
    }
  return (
    <div>
        <div className="pe-2">{questionIndex}. <CustomHTML html={lessonQuestion.content} /></div>
        {lessonQuestion.imageUpdated && <div>
            <img
                className={`${styles.lessonImage} img-fluid center-block p-1`} src={lessonQuestion.imagePath}
                alt={lessonQuestion.imageAltText ?? 'Question image'}
            />
        </div>}
        <div className="d-flex justify-content-center my-4">
            <Button onClick={onOpen}>
                Open Canvas
            </Button>
        </div>
        {openCanvas && 
            <FullScreenWrapper fullScreen={openCanvas} toggleFullScreen={() => setOpenCanvas(prev => !prev)}>
                <DrawingQuestion 
                    questionType="lesson"
                    lessonQuestionIndex={questionIndex}
                    lessonQuestion={lessonQuestion}
                    sectionNumber={sectionNumber}
                    autoSave={autoSave}
                    minimizeCanvas={onMinimize} />
            </FullScreenWrapper> 
        }
    </div>
  )
}

export default OpenCanvasButton
