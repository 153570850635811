type SectionId = string

interface Params {
    sectionGrades: Record<SectionId, number>
    sectionWeights: Record<SectionId, number>
}

interface Return {
    grade: number
    missingSection: boolean
}

export default function calculateTopicGrade({ sectionGrades, sectionWeights }: Params): Return {
    const sectionEntries = Object.entries(sectionGrades)

    const sectionWeightSum = Object.values(sectionWeights).reduce((p, v) => p + v, 0)

    let missingSection = false

    const studentWeights = Object.entries(sectionWeights).reduce((p, [id, v]) => {
        p[id] = v / sectionWeightSum
        return p
    }, {})

    let extraWeight = 0

    sectionEntries.forEach(([sectionId, grade]) => {
        if (grade == null) {
            missingSection = true
            extraWeight += studentWeights[sectionId]
            delete studentWeights[sectionId]
        }
    })

    if (extraWeight > 0) {
        const keys = Object.keys(studentWeights)
        keys.forEach((sectionId) => {
            studentWeights[sectionId] += extraWeight / keys.length
        })
    }

    const gradeSum = sectionEntries.reduce((prev, [sectionId, grade]) => {
        prev += grade * (studentWeights[sectionId] ?? 0)
        return prev
    }, 0)

    return {
        grade: gradeSum,
        missingSection: missingSection,
    }
}
