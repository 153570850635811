import axios from "axios"
import encodeURI from "./helpers/encodeURI"
import { FindStandardsProps, FindStandardsResponse } from "./lessons"

/**
 * Returns a set of standards for a particular topic and lesson
 */
export async function findTopicStandards(props: FindStandardsProps): Promise<FindStandardsResponse> {
    const standardsResult: FindStandardsResponse = { standards: [] }
    if (props.lessonId) {
        standardsResult.standards.push(...(await axios.get(encodeURI`/api/lessons/${props.lessonId}/standards`, {
            params: {
                standardId: props.standardId,
                core: props.core
            }
        }).then((response) => response.data.standards)))
    }
    if (props.topicId) {
        standardsResult.standards.push(...(await axios.get(encodeURI`/api/topics/${props.topicId}/standards`, {
            params: {
                standardId: props.standardId,
                core: props.core
            }
        }).then((response) => response.data.standards)))
    }
    return standardsResult
}
