import { useModel } from "@stem-sims/nexus"
import { Button, Col, Row } from "react-bootstrap"
import { PulseLoader } from "react-spinners"
import { addTopicStandard, deleteTopicStandard, getTopicStandards } from "shared/routes/curriculum/books"
import React, { useEffect, useState } from "react"
import ToolTip from "components/General/ToolTip"
import { getSubtopics, StandardsReturn, Subtopic } from "models/categories"


export default function TopicStandards({ topicId }: { topicId: string }) {
    
    const { loading: standardsLoading, response: standards, refreshModel } = useModel({
        model: getTopicStandards,
        props: { topicId }
    })

    const [availableStandards, setAvailableStandards] = useState<StandardsReturn[]>([]);
    const [showAddStandard, setShowAddStandard] = React.useState(false)
    const [selectedStandard, setSelectedStandard] = React.useState<string | null>(null)
    const [selectedStandardTopic, setSelectedStandardTopic] = React.useState<string | null>(null)
    const [selectedStandardSubtopic, setSelectedStandardSubtopic] = React.useState<Subtopic | null>(null)

    useEffect(() => {
        let standards: StandardsReturn[] = []
        getSubtopics().then(data => {
            standards = data
        }).finally(() => {
            setAvailableStandards(standards)
        })
    }, [])

    const deleteStandardCallback = React.useCallback(async (values) => {
        await deleteTopicStandard({ id: values.id })
        refreshModel()
    }, [refreshModel])

    const addStandardCallback = React.useCallback(async () => {
        await addTopicStandard({ topicId: topicId, subtopicId: selectedStandardSubtopic?.id || "", core: false })
        refreshModel()
    }, [refreshModel, topicId, selectedStandardSubtopic]) 

    

    function toggleAddStandard() {
        setSelectedStandard(null)
        setSelectedStandardTopic(null)
        setSelectedStandardSubtopic(null)
        setShowAddStandard(!showAddStandard)
    }

    function selectStandard(standard: string) {
        setSelectedStandard(standard)
        setSelectedStandardTopic(null)
        setSelectedStandardSubtopic(null)
    }

    function selectStandardTopic(topic: string) {
        setSelectedStandardTopic(topic)
        setSelectedStandardSubtopic(null)
    }

    return <div className="p-4">
        <h4>Standards</h4>
        <br />
        {standardsLoading && <>
            <PulseLoader />
        </>}
        {standards && standards.map((standard) => (
            <div key={standard.id} className="mb-2 d-flex justify-content-start align-items-center">
                <ToolTip title={standard.description}>
                    <span className="me-5 fw-bold">{standard.standard} - {standard.topicCode} - {standard.subtopicCode}</span>
                </ToolTip>
                <Button variant="danger" onClick={() => deleteStandardCallback(standard)}>Delete</Button>
            </div>
        ))}
        {!standardsLoading && ( !standards || standards.length === 0) && <>
            <p>No standards found</p>
        </>}
        {selectedStandardSubtopic && <Button variant="success" className="m-2" onClick={() => addStandardCallback()}>Add Standard</Button>}
        <Button className="m-2" onClick={toggleAddStandard}>{showAddStandard ? "Cancel" : "Add Standard"}</Button>
        {showAddStandard && 
            <div>
                { selectedStandardSubtopic && <p className="m-2 fw-bold">Selected: {selectedStandardSubtopic.standard} - {selectedStandardSubtopic.topic} - {selectedStandardSubtopic.subtopic}</p>}

                <Row>
                    <Col>
                        {availableStandards.map(standard => (
                            <div key={standard.standard} className="d-flex justify-content-start align-items-center"><Button className={"m-1 " + (selectedStandard === standard.standard ? "btn-primary" : "btn-secondary")} onClick={() => selectStandard(standard.standard)}>{standard.standard}</Button></div>
                        ))}
                    </Col>
                    <Col>
                        {selectedStandard && availableStandards.find(standard => standard.standard === selectedStandard)?.topics.map(topic => (
                            <div key={topic.topic} className="d-flex justify-content-start align-items-center"><Button className={"m-1 " + (selectedStandardTopic === topic.topic ? "btn-primary" : "btn-secondary")} onClick={() => selectStandardTopic(topic.topic)}>{topic.topic}</Button></div>
                        ))}
                    </Col>
                    <Col>
                        {selectedStandardTopic && availableStandards.find(standard => standard.standard === selectedStandard)?.topics.find(topic => topic.topic === selectedStandardTopic)?.subtopics.map(subtopic => (
                            <ToolTip title={subtopic.description}><div key={subtopic.subtopic} className="d-flex justify-content-start align-items-center"><Button className={"m-1 " + (selectedStandardSubtopic === subtopic ? "btn-primary" : "btn-secondary")} onClick={() => setSelectedStandardSubtopic(subtopic)}>{subtopic.subtopic}</Button></div></ToolTip>
                        ))}
                    </Col>
                </Row>
            </div>
        }
    </div>
}
