import { LessonSubmissionResponse } from "shared/types/moduleTypes"
import LessonResponseItem from "./components/LessonResponseItem"
import DrawingResponseItem from "./components/DrawingResponseItem"
import { AssessmentViewOptionsType } from "shared/types/assessmentTypes"

interface Props {
    lessonSubmission: LessonSubmissionResponse
    embeddedQuestionCount: number
    assessmentViewOption: AssessmentViewOptionsType
}

const StudentLessonResponses = ({ lessonSubmission, embeddedQuestionCount, assessmentViewOption } : Props) => {
    if (lessonSubmission === null) return <></>
  return <>
            <h2 className="h3 text-start pb-3 fw-bold text-center">Lesson Responses</h2>
            <ol className="mt-3">
                {lessonSubmission.responses?.map((response, index) => {
                    // For embedded questions, changes the index from numbered to lettered (0 -> A, etc.), based on the relative char codes
                    // For standalone questions, resets the displayed index to 1 for the first question
                    const questionBullet = response.section === 1 ?
                        String.fromCharCode(65 + index) :
                        String(index - embeddedQuestionCount)
                    if (response.isDrawing === "Yes") {
                        return <DrawingResponseItem assessmentViewOption={assessmentViewOption} questionNumber={questionBullet} response={response} completed={lessonSubmission.completed} index={index} />
                    } else {
                        return (
                            <LessonResponseItem responseType="lesson" questionNumber={questionBullet} response={response} completed={lessonSubmission.completed} index={index} /> 
                        )
                    }
                })}
            </ol>
        </>
}

export default StudentLessonResponses
