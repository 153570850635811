import CustomHTML from 'components/CustomHTML'
import Pane from 'components/Dashboards/General/Pane'
import styles from "../../studentSubmission.module.scss"
import { SubmissionResponse } from 'shared/types/moduleTypes'
import DrawingImageButton from 'pages/Dashboard/Teacher/Grades/Submission/components/DrawingImageButton'
import { AssessmentViewOptionsType } from 'shared/types/assessmentTypes'

interface Props {
    response: SubmissionResponse
    index: number
    assessmentViewOption: AssessmentViewOptionsType
}

const AssessmentDrawingResponseItem = ({response, index, assessmentViewOption} : Props) => {
    const showCorrectAnswer = !!(assessmentViewOption === "Answers" && (response.correctAnswer || response.exampleDrawing) && !response.isCorrect && (response.answer || response.drawingResponse))
    const showCorrectness = (assessmentViewOption === "Correct/Incorrect" || assessmentViewOption === "Answers") && response.drawingResponse

    return (<Pane className="mb-4 shadow-sm">
        <div className="text-start" key={index}>
            <span 
                className={"pe-2 " + (showCorrectness ? (response.isCorrect ? "text-success" : "text-danger"): "text-secondary")}>
                <span className="fw-bold">{response.questionNumber ?? (index + 1)}. </span> 
                <CustomHTML html={response.question} />
                {showCorrectness && <i className={`fas ${response.isCorrect ? `fa-check text-success` : `fa-times text-danger`} ms-2`} />}
            </span>
            <br /><br />
            {response.drawingResponse && 
                <div className="mb-2">
                    <p className="fw-bold mb-2">Your drawing answer: </p>
                    <img src={response.drawingResponse} alt="Student's drawing response" className={`shadow-sm border border-1 ${styles.studentDrawingAnswerImage}`} />
                    {showCorrectAnswer && 
                        <DrawingImageButton 
                            drawingUrl={response?.exampleDrawing} 
                            title="Example Drawing"
                            optionalAnswer={response?.correctAnswer} />}
                </div>}
        </div>
    </Pane>)
}

export default AssessmentDrawingResponseItem
