import { Button } from "react-bootstrap"

const SelectNoneAll = ({selectNone, selectAll} : {selectNone: () => void, selectAll: () => void}) => {
  return (
    <div className="d-flex justify-content-between w-100">
        <div>
            <Button
                variant="outline-theme"
                onClick={selectNone}
            >Select None</Button>
            <Button
                variant="outline-theme mx-3"
                onClick={selectAll}
            >Select All</Button>
        </div>
    </div>
  )
}

export default SelectNoneAll
