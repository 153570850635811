import Pane from "components/Dashboards/General/Pane"
import FAIcon from "components/General/FAIcon"
import { Formik, Field } from "formik"
import React, { useRef, useState } from "react"
import { Row, Col, Form, Button, Modal, Image } from "react-bootstrap"
import { BeatLoader, PulseLoader } from "react-spinners"
import { updateSectionQuestion, createSectionQuestion, deleteSectionQuestion, deleteDrawingImg } from "shared/routes/curriculum/books"
import { CurriculumAnswer, CurriculumSection } from "shared/types/curriculumTypes"
import TextareaField from "./TextareaField"

import styles from "./editingStyles.module.scss"
const { selectable } = styles

interface Props {
    section: CurriculumSection
    loading: boolean
    refreshModel: () => Promise<unknown> | void
}

export default function EditingSectionQuestions({ section, loading, refreshModel }: Props) {
    const [questionDeleteId, setQuestionDeleteId] = useState(null)
    const [answerDeleteIndex, setAnswerDeleteIndex] = useState(null)

    // drawing image related states
    const questionImageRef = useRef(null)
    const exampleImageRef = useRef(null)
    const [submittingQuestionImg, setSubmittingQuestionImg] = useState<boolean>(false)
    const [submittingExampleImg, setSubmittingExampleImg] = useState<boolean>(false)

    const deleteImage = async (questionId: string, typeToDelete: "questionImage" | "exampleImage") => {
        await deleteDrawingImg({sectionId: section.id, questionId: questionId, typeToDelete: typeToDelete})
        refreshModel()
        if (questionImageRef.current) {
            questionImageRef.current.value = ""
        }
        if (exampleImageRef.current) {
            exampleImageRef.current.value = ""
        }
    }

    return <>
        <h4>Questions</h4>
        {(!section.questions && loading) && <>
            <PulseLoader />
        </>}

        {(section.questions || !loading) && <>
            {section.questions.map((question, index) => (
                <Row key={question.id}>
                    <Col>
                        <Pane className={selectable}>
                            <Formik
                                initialValues={{
                                    question: question.question ?? "",
                                    correctAnswer: question.correctAnswer ?? "",
                                    answers: question.answers as Partial<CurriculumAnswer>[] ?? [],
                                    drawingQuestionImage: null,
                                    drawingExampleImage: null
                                }}
                                enableReinitialize
                                onSubmit={async (values) => {
                                    let formData = new FormData()
                                    formData.append("questionId", question.id)

                                    for (const [key, value] of Object.entries(values)) {
                                        if (value != null && (question.type !== "Drawing" || key !== "answers")) {
                                            const convValue = key === "answers" ? JSON.stringify(value) : value
                                            formData.append(key, convValue)
                                        }
                                    }

                                    await updateSectionQuestion(formData)
                                    refreshModel()
                                    setSubmittingQuestionImg(false)
                                    setSubmittingExampleImg(false)
                                }}
                            >{({ handleSubmit, setFieldValue, values }) => (<form onSubmit={handleSubmit}>
                                <Form.Group className="my-2" controlId="question">
                                    <Form.Label>Question</Form.Label>
                                    <Form.Control as={TextareaField} name="question" onBlur={() => handleSubmit()} />
                                </Form.Group>

                                <Form.Group className="my-2" controlId="correctAnswer">
                                    <Form.Label>Correct Answer</Form.Label>
                                    <Form.Control as={TextareaField} name="correctAnswer" onBlur={() => handleSubmit()} />
                                </Form.Group>

                                {question.type === "Drawing" &&
                                    <>
                                        <Form.Group className="my-2" controlId="questionImage">
                                            <div>
                                                <Form.Label>Drawing Question Image</Form.Label>
                                                {submittingQuestionImg ? 
                                                    <div><BeatLoader /></div> : 
                                                    <>
                                                        {question.questionImageUrl &&
                                                            <div>
                                                                <Image 
                                                                    fluid
                                                                    src={question.questionImageUrl} 
                                                                    alt={'Drawing Question Image'} 
                                                                    className="rounded mb-2 shadow-sm border border-1"/>
                                                                <div className="d-flex justify-content-center">
                                                                    <Button 
                                                                        variant="danger" 
                                                                        className="btn-sm my-3 d-block" 
                                                                        type="button" 
                                                                        onClick={() => {
                                                                            deleteImage(question.id, "questionImage")
                                                                            delete values.drawingQuestionImage
                                                                        }}>
                                                                        Delete Image
                                                                    </Button>
                                                                </div>
                                                            </div>}
                                                        <Form.Control
                                                            ref={questionImageRef}
                                                            type="file"
                                                            accept=".png, image/png, .jpg, .jpeg, image/jpeg"
                                                            name="drawingQuestionImage" 
                                                            onChange={(event) => {
                                                                const el = event.target as HTMLInputElement 
                                                                setFieldValue("drawingQuestionImage", el.files[0])
                                                                setFieldValue("drawingExampleImage", null) // this stops saving the duplicated example img file on Spaces
                                                                setSubmittingQuestionImg(true)
                                                                handleSubmit()
                                                            }} />
                                                    </>}
                                            </div>
                                            <div className="mt-3">
                                                <Form.Label>Example Drawing Image</Form.Label>
                                                {submittingExampleImg ?
                                                    <div><BeatLoader /></div> :
                                                    <>
                                                        {question.exampleImageUrl &&
                                                            <div>
                                                                <Image 
                                                                    fluid
                                                                    src={question.exampleImageUrl} 
                                                                    alt={'Example Question Image'} 
                                                                    className="rounded mb-2 shadow-sm border border-1"/>
                                                                <div className="d-flex justify-content-center">
                                                                    <Button 
                                                                        variant="danger" 
                                                                        className="btn-sm my-3 d-block" 
                                                                        type="button" 
                                                                        onClick={() => {
                                                                            deleteImage(question.id, "exampleImage")
                                                                            delete values.drawingExampleImage
                                                                        }}>
                                                                        Delete Image
                                                                    </Button>
                                                                </div>
                                                            </div>}
                                                        <Form.Control
                                                            ref={exampleImageRef}
                                                            type="file"
                                                            accept=".png, image/png, .jpg, .jpeg, image/jpeg"
                                                            name="drawingExampleImage" 
                                                            onChange={(event) => {
                                                                const el = event.target as HTMLInputElement 
                                                                setFieldValue("drawingExampleImage", el.files[0])
                                                                setFieldValue("drawingQuestionImage", null) // this stops saving the duplicated question img file on Spaces
                                                                setSubmittingExampleImg(true)
                                                                handleSubmit()
                                                            }} />
                                                    </>
                                                }
                                            </div>
                                        </Form.Group>
                                    </>
                                }

                                {question.type === "MultipleChoice" && <>
                                    {values.answers?.map((answer, answerIndex) => (<React.Fragment key={answer.id ?? `new-${answerIndex}`}>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <Form.Group className="my-2" controlId={`answers[${answerIndex}].answer`}>
                                                    <Form.Label>Answer {answer.letter}</Form.Label>
                                                    <Form.Control as={Field} name={`answers[${answerIndex}].answer`} onBlur={() => handleSubmit()} />
                                                </Form.Group>

                                                <Form.Group className="my-2" controlId={`answers[${answerIndex}].explanation`} >
                                                    <Form.Label>Explanation</Form.Label>
                                                    <Form.Control as={TextareaField} name={`answers[${answerIndex}].explanation`} onBlur={() => handleSubmit()} />
                                                </Form.Group>
                                            </Col>

                                            <Col xs="auto" onClick={() => {
                                                setQuestionDeleteId(question.id)
                                                setAnswerDeleteIndex(answerIndex)
                                            }}>
                                                <Button variant="danger"><FAIcon iconName={"skull"} /></Button>
                                            </Col>
                                        </Row>
                                    </React.Fragment>))}

                                    <Row className="my-2">
                                        <Col>
                                            <Button onClick={async () => {
                                                await updateSectionQuestion({
                                                    questionId: question.id,
                                                    answers: values.answers.concat([{ answer: "" }])
                                                })
                                                refreshModel()
                                            }}><FAIcon iconName="plus" /> Answer</Button>
                                        </Col>
                                    </Row>
                                </>}
                            </form>)}</Formik>
                        </Pane>
                    </Col>

                    <Col xs="auto" onClick={async () => {
                        await updateSectionQuestion({
                            questionId: question.id,
                            order: index,
                        })
                        refreshModel()
                    }}>
                        <Button disabled={index === 0}><FAIcon iconName={"sort-up"} /></Button>
                    </Col>

                    <Col xs="auto" onClick={async () => {
                        await updateSectionQuestion({
                            questionId: question.id,
                            order: index + 2
                        })
                        refreshModel()
                    }}>
                        <Button disabled={index === section.questions?.length - 1}><FAIcon iconName={"sort-down"} /></Button>
                    </Col>

                    <Col xs="auto" onClick={() => setQuestionDeleteId(question.id)}>
                        <Button variant="danger"><FAIcon iconName={"skull"} /></Button>
                    </Col>
                </Row>
            ))}

            <Row className="my-2">
                <Col>
                    <Button onClick={async () => {
                        await createSectionQuestion({ sectionId: section?.id, type: "Text" })
                        refreshModel()
                    }}><FAIcon iconName="plus" />Free Response</Button>
                </Col>

                <Col>
                    <Button onClick={async () => {
                        await createSectionQuestion({ sectionId: section?.id, type: "MultipleChoice" })
                        refreshModel()
                    }}><FAIcon iconName="plus" />Multiple Choice</Button>
                </Col>

                <Col>
                    <Button onClick={async () => {
                        await createSectionQuestion({ sectionId: section?.id, type: "Drawing" })
                        refreshModel()
                    }}><FAIcon iconName="plus" />Drawing</Button>
                </Col>
            </Row>
        </>}

        <Modal show={questionDeleteId && answerDeleteIndex == null} onHide={() => setQuestionDeleteId(null)}>
            <Modal.Header closeButton>
                Delete Question
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <h3>Are you sure you wish to delete this question?</h3>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={async () => {
                    await deleteSectionQuestion({ questionId: questionDeleteId })
                    setQuestionDeleteId(null)
                    refreshModel()
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>

        <Modal show={questionDeleteId && answerDeleteIndex != null} onHide={() => {
            setQuestionDeleteId(null)
            setAnswerDeleteIndex(null)
        }}>
            <Modal.Header closeButton>
                Delete Answer
            </Modal.Header>

            <Modal.Body>

                <Row>
                    <h3>Are you sure you wish to delete this answer?</h3>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="danger" onClick={async () => {
                    const oldAnswers = section.questions.find(q => q.id === questionDeleteId).answers
                    const newAnswers = oldAnswers.slice(0, answerDeleteIndex).concat(oldAnswers.slice(answerDeleteIndex + 1))
                    await updateSectionQuestion({
                        questionId: questionDeleteId,
                        answers: newAnswers
                    })
                    setQuestionDeleteId(null)
                    setAnswerDeleteIndex(null)
                    refreshModel()
                }}>Delete</Button>
            </Modal.Footer>
        </Modal>
    </>
}
