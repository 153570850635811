import Pane from "./Pane"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import styles from "./general.module.scss"
import { CalendarAssignment } from "../Student/Overview"
import {useContext, useEffect, useMemo, useState } from "react"
import { beforeCurrentDay, formatToCalendarDate } from "helpers/dateHelper"
import { StudentAssignmentResponse } from "shared/types/studentTypes"
import { toast } from "react-toastify"
import { useNavigate } from "react-router"
import { getStudentSubmissionId } from "helpers/getSubmissionId"
import { SidebarContext } from "components/Sidebar/SidebarContext"
import { AssignmentResponse } from "shared/types/teacherTypes"
import { AuthContext } from "AuthContext"
import lessons from "shared/lessons"
import { Tooltip } from "bootstrap"
import ColorLegend from "./ColorLegend"

export const NO_SUBMISSIONS = "No submissions"
export const SUBMISSIONS_EXIST = "Submissions exist"
export const DUE_WITH_SUBMISSIONS = "Due with submissions"
export const DUE_WITHOUT_SUBMISSIONS = "Due without submissions"

export const teacherToStudentLabels = {
    [NO_SUBMISSIONS]: "Not Submitted",
    [SUBMISSIONS_EXIST]: "Submitted",
    [DUE_WITHOUT_SUBMISSIONS]: DUE_WITHOUT_SUBMISSIONS,
    [DUE_WITH_SUBMISSIONS]: "Due but submitted"
}

const Calendar = ({ assignments } : {assignments: StudentAssignmentResponse[] | AssignmentResponse[]}) => {

    const BREAKPOINT = 600
    const statusToColor = useMemo(() => {
        return {
            [NO_SUBMISSIONS]: "#3788D8",
            [SUBMISSIONS_EXIST]: "#198754",
            [DUE_WITH_SUBMISSIONS]: "#23c97c",
            [DUE_WITHOUT_SUBMISSIONS]: "red"
        }
    }, [])

    let tooltip = null

    const [calendarAssignments, setCalendarAssignments] = useState<CalendarAssignment[]>(null)
    const [calendarView, setCalendarView] = useState<"dayGridMonth" | "listMonth">(window.innerWidth > BREAKPOINT ? "dayGridMonth" : "listMonth")
    const [year, setYear] = useState<"numeric" | "2-digit">(window.innerWidth > BREAKPOINT ? "numeric" : "2-digit")
    const [month, setMonth] = useState<"short" | "long">(window.innerWidth > BREAKPOINT ? "long" : "short")
    const [legendColors, setLegendColors] = useState<string[]>([])

    const { isStudent } = useContext(AuthContext)
    const { collapsed } = useContext(SidebarContext)

    const navigate = useNavigate()

    const openAssignment = async (e: any) => {
        if (tooltip) {
            tooltip.dispose()
            tooltip = null
        }
        const assignmentObj = e?.event?.extendedProps?.assignmentObj as AssignmentResponse | StudentAssignmentResponse
        if (isStudent) {
            //open student assignment
            try {
                const studentSubmissionId = await getStudentSubmissionId(assignmentObj as StudentAssignmentResponse)
                navigate("/dashboard/student/assignments/assignment", {
                    state: {
                        submissionId: studentSubmissionId,
                        assignment: assignmentObj,
                    }
                })
            } catch (err) {
                toast.error(err?.response?.data?.message ?? "Unable to start assignment, please try again.")
            }
        } else {
            //open teacher assignment
            if (assignmentObj.lessonVariety === "Guided" || assignmentObj.lessonVariety === "Topic") {
                if(assignmentObj.lessonId) {
                    var lesson = await lessons.findById(assignmentObj.lessonId)
                }
                navigate("/dashboard/teacher/assignments/grading", {
                    state: {
                        assignment: assignmentObj,
                        lesson: lesson
                    }
                })
            } else if (assignmentObj.lessonVariety === "Pure Inquiry") {
                navigate("/dashboard/teacher/assignments/grading", {
                    state: {
                        assignment: assignmentObj  
                    }
                })
            }
        }
    }

    const decideAssignmentStatus = (assignment) => {
        if (!assignment.dueDate) {
            return null
        }

        const status = beforeCurrentDay(assignment.dueDate) ? ((assignment.status === "Complete" || assignment?.submissions?.length > 0) ? 
            DUE_WITH_SUBMISSIONS : DUE_WITHOUT_SUBMISSIONS) : ((assignment.status === "Complete" || assignment?.submissions?.length > 0) ? 
            SUBMISSIONS_EXIST : NO_SUBMISSIONS)
        return status
    }

    // Create an array of calendar events (assignments) from the original assignments array
    useEffect(() => {
        const calendarEvents = assignments?.map(a => {
            const status = decideAssignmentStatus(a)

            setLegendColors(prev => {
                if (prev.includes(status) || status === null) {
                    return prev
                }
                return [...prev, status]
            })

            return ({
                assignmentObj: a,
                title: a.title,
                date: formatToCalendarDate(a.dueDate),
                backgroundColor: statusToColor[status]
            })
        })

        setCalendarAssignments(calendarEvents)
    }, [assignments, statusToColor])

    // This is not ideal but this useEffect is used for manually triggering a window resize event 
    // so that FullCalendar is auto resized whenever sidebar collapse/expand
    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [collapsed])

    useEffect(() => {
        const handleWindowResize = () => {
          const width = window.innerWidth
          if (width < BREAKPOINT) {
            setYear("2-digit")
            setMonth("short")
            setCalendarView("listMonth")
          } else {
            setYear("numeric")
            setMonth("long")
            setCalendarView("dayGridMonth")
          }
        }
    
        window.addEventListener('resize', handleWindowResize)
    
        return () => {
          window.removeEventListener('resize', handleWindowResize)
        }
    }, [])

  return (
    <Pane className="shadow class-calendar">
        <div className={styles.calendar}>
            <FullCalendar
                height="auto"
                plugins={[ dayGridPlugin, listPlugin ]}
                initialView={calendarView}
                events={calendarAssignments}
                eventClick={openAssignment}
                titleFormat={{
                    month: month,
                    year: year
                }}
                windowResize={(arg) => {
                    if (window.innerWidth > BREAKPOINT) {
                        arg.view.calendar.changeView("dayGridMonth")
                    } else {
                        arg.view.calendar.changeView("listMonth")
                    }
                }}
                headerToolbar={{ start: "today", center: "title", end: "prev,next" }}
                eventMouseEnter={(info) => {
                    const status = decideAssignmentStatus(info.event?.extendedProps?.assignmentObj)
                    tooltip = new Tooltip(info.el, {
                        title: `<div><strong>${info.event.title}</strong><p>(${isStudent ? teacherToStudentLabels[status] : status})</p></div>`,
                        placement: "top",
                        trigger: "hover",
                        html: true
                      })
                
                      tooltip.show()
                }}
                eventMouseLeave={() => {
                    if (tooltip) {
                        tooltip.dispose()
                        tooltip = null
                    }
                }}
            />
            {legendColors.length > 0 && <ColorLegend colors={legendColors} />}
        </div>
    </Pane>
  )
}

export default Calendar
