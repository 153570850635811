import { Button, Form, Modal } from "react-bootstrap"
import { toast } from "react-toastify"
import * as teacher from "../../../../../../shared/routes/teacher"
import { useEffect, useState } from "react"
import { ClassResponse, CopyOption } from "shared/types/teacherTypes"
import CopyOptionToggle from "./CopyOptionToggle"
import { getLessonPlans } from "shared/routes/lessonPlans"
import { useModel } from "@stem-sims/nexus"
import { Link } from "react-router-dom"
import SelectNoneAll from "./SelectNoneAll"

export interface Props {
    selectedAssignmentIds: string[]
    show: boolean
    onHide: () => void
    onSuccess: () => void
    activeClass: ClassResponse
}

const BulkCopyModal = ({ show, onHide, onSuccess, selectedAssignmentIds, activeClass } : Props) => {
    const [classes, setClasses] = useState<ClassResponse[]>(null)
    const [selectedClassesIds, setSelectedClassesIds] = useState<string[]>([])
    const [selectedLessonPlanIds, setSelectedLessonPlanIds] = useState<string[]>([])
    const [copyOption, setCopyOption] = useState<CopyOption>("class")

    useEffect(() => {
        teacher.getClasses().then(res => {
            //filter out the current class so that user cannot select it
            const classesResponse = res.filter(c => c.id !== activeClass.id && c.archived !== "Yes")
            setClasses(classesResponse)
        })
    }, [activeClass?.id])

    const { response: lessonPlans, refreshModel: refreshLessonPlans } = useModel({
        model: getLessonPlans
    })

    const onBulkCopy = () => {
        teacher.bulkCopy({ copyOption, assignmentIds: selectedAssignmentIds, classIds: selectedClassesIds, lessonPlanIds: selectedLessonPlanIds })
        .then(() => {
            setSelectedClassesIds([])
            setSelectedLessonPlanIds([])
            onSuccess()
            toast.success("Successfully copied selected assignments.")
        })
        .catch(() => toast.error("Something went wrong copying assignments."))
    }

    const hideCopyModal = () => {
        setSelectedClassesIds([])
        setSelectedLessonPlanIds([])
        onHide()
    }

    return( 
        <Modal show={show} onHide={hideCopyModal}>
            <Modal.Header className="border-0" closeButton>
                <h3 className="w-100 text-center">Copy Selected Assignments</h3>
            </Modal.Header>
            <Modal.Body className="mx-5 text-center">
                <CopyOptionToggle
                    onClassChosen={() => {
                        setCopyOption("class")
                        setSelectedLessonPlanIds([])
                    }}
                    onLessonPlanChosen={() => {
                        setCopyOption("lesson-plan")
                        refreshLessonPlans()
                        setSelectedClassesIds([])
                    }}
                    copyOption={copyOption}
                />
                {copyOption === "class" && 
                    <div className="mt-2">
                        <p className="fw-bold text-muted text-start">
                            {classes?.length === 0 ? `You must create another class to have a destination for copied assignments.` : `Selected assignments will be copied to all the classes you select below.`}
                        </p>
                        {classes?.length !== 0 &&
                            <SelectNoneAll
                                selectNone={() => {
                                    setSelectedClassesIds([])
                                }}
                                selectAll={() => {
                                    const classIds = classes.map(c => c.id)
                                    setSelectedClassesIds(classIds)
                                }}
                            />}
                        <div className="mt-3">
                            {classes?.map(c => {
                                return <Form.Check
                                        key={`select-${c.id}`}
                                        className="text-start"
                                        checked={selectedClassesIds.includes(c.id)}
                                        id={`select-${c.id}`}
                                        label={c.className}
                                        onChange={(e) => {
                                            if (!e.target.checked) {
                                                const newClasses = selectedClassesIds.filter((value) => value !== c.id)
                                                setSelectedClassesIds(newClasses)
                                            } else {
                                                const newClasses = selectedClassesIds.concat([c.id])
                                                setSelectedClassesIds(newClasses)
                                            }
                                        }}
                                    />
                            })}
                        </div>
                    </div>}

                {copyOption === "lesson-plan" &&
                    <div className="mt-2">
                        {lessonPlans?.length === 0 ?
                            <p className="fw-bold text-muted text-start">
                                You must create a lesson plan to contain the selected assignments. Add a new lesson plan <Link to="/dashboard/teacher/lesson-plans" >here.</Link>
                            </p> :
                            <p className="fw-bold text-muted text-start">
                                Selected assignments will be copied to all the lesson plans you select below.
                            </p>}
                        {lessonPlans?.length !== 0 &&
                            <SelectNoneAll 
                                selectNone={() => {
                                    setSelectedLessonPlanIds([])
                                }}
                                selectAll={() => {
                                    const planIds = lessonPlans.map(p => p.lessonPlanId)
                                    setSelectedLessonPlanIds(planIds)
                                }} />}
                        <div className="mt-3">
                            {lessonPlans?.map(plan => {
                                return <Form.Check
                                        key={`select-${plan.lessonPlanId}`}
                                        className="text-start"
                                        checked={selectedLessonPlanIds.includes(plan.lessonPlanId)}
                                        id={`select-${plan.lessonPlanId}`}
                                        label={plan.planName}
                                        onChange={(e) => {
                                            if (!e.target.checked) {
                                                const newPlans = selectedLessonPlanIds.filter((value) => value !== plan.lessonPlanId)
                                                setSelectedLessonPlanIds(newPlans)
                                            } else {
                                                const newPlans = selectedLessonPlanIds.concat([plan.lessonPlanId])
                                                setSelectedLessonPlanIds(newPlans)
                                            }
                                        }}
                                    />
                            })}
                        </div>
                    </div>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button
                    variant="primary"
                    disabled={selectedClassesIds.length === 0 && selectedLessonPlanIds.length === 0}
                    onClick={onBulkCopy}>
                    Copy
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BulkCopyModal
