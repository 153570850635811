import FloatingPage from "../General/FloatingPage"
import { useContext } from "react"
import { Row, Col } from "react-bootstrap";
import { BeatLoader } from "react-spinners";
import styles from "./Curriculum.module.scss";
import { AuthContext } from "AuthContext";
import { BookContext } from "./BookContext";
import TextbookItem from "./TextbookItem";
import { useNavigate } from "react-router"
import { createSearchParams, useSearchParams } from "react-router-dom";

export default function TextbookPage() {
    const authInfo = useContext(AuthContext)
    const bookInfo = useContext(BookContext)
    const textbooks = bookInfo.textbooks
    const navigate = useNavigate()
    const [ searchParams ] = useSearchParams()
    const lessonPlanId = searchParams.get("lessonPlanId")

    return (
        <FloatingPage className={styles.background}>
            {authInfo.hasCurriculumAccess && (
                <>
                <h1>Textbooks</h1>
                <Row className="my-5 justify-content-center">
                {textbooks.length === 0 ? (
                    <Col md={4}>
                        <h3>Loading textbooks...</h3>
                        <BeatLoader size={10} speedMultiplier={0.8} />
                    </Col>
                ) : (
                    <>
                        {textbooks.map((textbook) => <TextbookItem key={textbook.id} textbook={textbook} onClick={() => {
                            navigate({
                                pathname: `/books/units/${textbook.id}`,
                                search: lessonPlanId ? createSearchParams({ lessonPlanId }).toString() : undefined
                            })
                        }} />)}
                    </>
                    )}
                </Row>
                </>
            )}
        </FloatingPage>
    );
}
