import { Col, Pagination, Row } from "react-bootstrap"
import Pane from "../General/Pane"
import styles from "./Student.module.scss"
import { toast } from "react-toastify"
import { StudentAssignmentResponse } from "shared/types/studentTypes"
import { formatToLongDate } from "helpers/dateHelper"
import { beforeCurrentDay, dueSoon } from "helpers/dateHelper"
import { getStudentSubmissionId, getTopicSubmissionId } from "helpers/getSubmissionId"
import { useNavigate } from "react-router"
import { HourglassSplit, HourglassBottom } from "react-bootstrap-icons"
import { AssignmentResponse } from "shared/types/teacherTypes"
import { useContext, useEffect, useState } from "react"
import { AuthContext } from "AuthContext"
import lessons from "shared/lessons"
import { TeacherGradesState } from "components/Lessons/Grading/GradeList/TeacherGrades"
import ToolTip from "components/General/ToolTip"
import { getSubmissions } from "shared/routes/assignments"

interface Props {
    filteredAssignments: StudentAssignmentResponse[] | AssignmentResponse[]
}

const AssignmentsOverview = ({ filteredAssignments } : Props) => {

    const ASSIGNMENTS_PER_PAGE = 5
    const PAGES_PER_SET = 5
    
    const [paginatedAssignments, setPaginatedAssignments] = useState<StudentAssignmentResponse[] | AssignmentResponse[]>(filteredAssignments ?? [])
    const [currentPage, setCurrentPage] = useState<number>(1) // current page out of all pages - 1 out of [1,2,3,4,5,6,7,8,9,10]
    const [pageRange, setPageRange] = useState<number[]>([]) // current page range: [1,2,3,4,5] out of [1,2,3,4,5,6,7,8,9,10]
    const [pageRangeNumber, setPageRangeNumber] = useState<number>(1) // current page range number: 1 = [1,2,3,4,5], 2 = [6,7,8,9,10]
    const [totalPages, setTotalPages] = useState<number[]>([]) // all pages: [1,2,3,4,5,6,7,8,9,10]
    
    const navigate = useNavigate()
    const { isStudent } = useContext(AuthContext)

    const openAssignment = async (assignment: StudentAssignmentResponse | AssignmentResponse) => {
        if (isStudent) {
            try {
                let studentSubmissionId = null
                let startFromAssessment = false
                if (assignment?.topicId) {
                    studentSubmissionId = await getTopicSubmissionId(assignment as StudentAssignmentResponse)
                } else {
                    const recentSubmission = (await getSubmissions({ assignmentId: assignment.id })).data?.[0]
                    studentSubmissionId = await getStudentSubmissionId(assignment as StudentAssignmentResponse)
                    startFromAssessment = (recentSubmission?.lessonSubmittedDate && recentSubmission?.assessmentGrade === null && recentSubmission?.lessonGrade == null) ? true : false
                }
                navigate("/dashboard/student/assignments/assignment", {
                    state: {
                        submissionId: studentSubmissionId,
                        assignment: assignment,
                        continueAssessment: startFromAssessment
                    }
                })
            } catch (err) {
                toast.error(err?.response?.data?.message ?? "Unable to start assignment, please try again.")
            }
        } else {
            if (assignment.lessonVariety === "Guided" || assignment.lessonVariety === "Topic") {
                if(assignment.lessonId) {
                    var lesson = await lessons.findById(assignment.lessonId)
                }
                const TeacherGradesState: TeacherGradesState = {
                    assignment: assignment,
                    lesson: lesson
                }
                navigate("/dashboard/teacher/assignments/grading", {
                    state: TeacherGradesState
                })
            } else if (assignment.lessonVariety === "Pure Inquiry") {
                const TeacherGradesState: TeacherGradesState = {
                    assignment: assignment  
                }
                navigate("/dashboard/teacher/assignments/grading", {
                    state: TeacherGradesState
                })
            }
        }
    }

    /** Calculate the number of pages based on the the list of assignments */
    useEffect(() => {
        const totalPages: number = Math.ceil((filteredAssignments?.length ?? 0) / ASSIGNMENTS_PER_PAGE)
        const totalPagesArr: number[] = new Array(totalPages).fill(0).map((_, i) => i + 1)
        setTotalPages(totalPagesArr)
    }, [filteredAssignments])

    /** Slice the original assignments array into 5 assignments for each page */
    useEffect(() => {
        const start = (currentPage - 1) * ASSIGNMENTS_PER_PAGE
        const end = start + ASSIGNMENTS_PER_PAGE
        setPaginatedAssignments(filteredAssignments?.slice(start, end))
    }, [currentPage, filteredAssignments])

    /** Calculate which pages should show in one set */
    useEffect(() => {
        const start = (pageRangeNumber - 1 ) * PAGES_PER_SET
        const end = start + PAGES_PER_SET

        const currentPageRange = totalPages?.slice(start, end)
        setPageRange(currentPageRange)
        setCurrentPage(currentPageRange[0]) // select the first page as current page out of the page set
    }, [pageRangeNumber, totalPages])

  return (
    <div className={`mt-4 p-2`}>
        {paginatedAssignments.map((assignment: StudentAssignmentResponse | AssignmentResponse) => {
            const isDueSoon = dueSoon(assignment.dueDate)
            const isPastDue = beforeCurrentDay(assignment.dueDate)

            const topicType = !!assignment.topicId
            const lessonType = !topicType && !!assignment.lessonId

            const tooltipTitle = isStudent ? ((assignment as StudentAssignmentResponse)?.status === "Incomplete" ? "Resume assignment" : "Start assignment") : "Grade assignment"

            return <ToolTip title={tooltipTitle} key={assignment.id}>
                <div key={assignment.id} onClick={() => openAssignment(assignment)}>
                        <Pane className={`shadow-sm mt-2 ${styles.assignmentOverview}`}>
                            <Row>
                                <Col className="text-start" sm={12} md={7}>
                                    <p className={`fw-bold ${styles.assignmentTitle}`}>
                                        {assignment.title}
                                        <span className={`rounded ms-2 px-2 ${topicType ? styles.labelTagTopic : lessonType ? styles.labelTagLesson : styles.labelTagAssessment}`}>
                                            {topicType ? "topic" : (lessonType ? "lesson" : "assessment")}
                                        </span>
                                    </p>
                                    <p className="text-muted fs-6 fw-bold mb-0">{assignment.moduleName}</p>
                                </Col>
                                <Col className={`text-start ${styles.dueDateContainer}`} sm={12} md={5}>
                                    {assignment.dueDate && (
                                        <>
                                            <p className={`m-0`}>
                                                <span className="fw-bold">Due:&nbsp;</span><span className={`text-success ${isPastDue && `text-danger`}`}>{formatToLongDate(assignment.dueDate)}</span>
                                            </p>
                                            {(isDueSoon || isPastDue) && 
                                                <p className={`${styles.dueStatus} ${isDueSoon && `bg-success`} ${isPastDue && `bg-danger`}
                                                    fw-bold text-nowrap text-white mb-0 px-3 py-1 rounded text-center fw-bold d-inline-block mt-1`}>
                                                    {isDueSoon && <><HourglassSplit className="me-2" /> due soon</>}
                                                    {isPastDue && <><HourglassBottom className="me-2" /> past due</>}
                                                </p>
                                            }
                                        </>)}
                                </Col>
                            </Row>
                        </Pane>
            </div>
            </ToolTip>})
        }

        {totalPages.length > 1 && 
            <Pagination className="mt-3">
                {totalPages.length > PAGES_PER_SET && 
                    <Pagination.Prev disabled={pageRangeNumber === 1} onClick={() => setPageRangeNumber(prev => prev - 1)} className="me-2" />}

                {pageRange?.map((pageNumber) => {
                    return (
                        <Pagination.Item
                            className="me-2"
                            onClick={() => setCurrentPage(pageNumber)}
                            key={pageNumber}
                            active={pageNumber === currentPage}
                            data-testid={`page-${pageNumber}`}
                            >
                            {pageNumber}
                        </Pagination.Item>
                    )
                })}

                {totalPages.length > PAGES_PER_SET && 
                    <Pagination.Next disabled={pageRangeNumber === (Math.ceil(totalPages.length / PAGES_PER_SET))} onClick={() => setPageRangeNumber(prev => prev + 1)} />}
            </Pagination>
        }
    </div>
  )
}

export default AssignmentsOverview
