import StudentClassQuizForm from 'components/Quiz/StudentClassQuizForm'
import { useFormik, FormikProvider } from 'formik'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import * as teacher from "shared/routes/teacher"
import * as Yup from "yup"

interface Props {
    show: boolean
    hide: () => void
    afterSubmit: () => void
    origin: "AddStudent" | "Auto"
}

const ClassAccountCreate = ({ show, hide, origin, afterSubmit } : Props) => {

    const initialValues = {
        username: "",
        password: "",
        confirmPassword: ""
    }

    const onSubmit = (values) => {
        return teacher.createStudentClassAccount(values)
            .then((res) => {
                toast.success(res)
                hide()
                afterSubmit()
            })
            .catch((err) => {
                toast.error(err.response.data)
            })
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit,
        enableReinitialize: true,
        validationSchema: Yup
            .object()
            .shape({
                username: Yup
                    .string()
                    .max(150)
                    .label("Username")
                    .required("Username is required."),
                password: Yup
                    .string()
                    .label("Password")
                    .required("Password is required.")
                    .min(5, "Password must be 5 characters long")
                    .max(150)
                    .oneOf([Yup.ref('confirmPassword'), null], 'The passwords entered do not match.'),
                confirmPassword: Yup
                    .string()
                    .max(150)
                    .label("Confirm Password")
                    .required("Confrim Password is required."),
            })
    })

  return (
    <Modal show={show} onHide={hide}>
        <Modal.Header className="border-0" closeButton>
            <h3 className="w-100 text-center">Create Class Account</h3>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center justify-content-center">
            <FormikProvider value={formik}>
                <StudentClassQuizForm formik={formik} />
            </FormikProvider>
        </Modal.Body>
    </Modal>
  )
}

export default ClassAccountCreate
