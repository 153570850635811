import { CurriculumSection } from "shared/types/curriculumTypes"

export default function makeDefaultSectionWeights({ sections }: { sections: CurriculumSection[] }) {
    const ungradedSections = sections.filter((v) => !v.graded).length
    const defaultWeights = sections.reduce((prev, section) => {
        prev[section.id] = section.graded ? 1 / (sections.length - ungradedSections) : 0
        return prev
    }, {})
    return defaultWeights
}
