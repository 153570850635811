import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import * as auth from "./models/auth"

import WebsiteRoute from "./components/Routes/WebsiteRoute"
import WebAppRoute from "./components/Routes/WebAppRoute"
import ReactPixel from 'react-facebook-pixel'

/**
 * Routes
 */
import PureInquiryPage from "./components/Lessons/PureInquiry/PureInquiry"
import NotFoundPage from "./components/NotFound"
import LandingPage from "./components/Landing/LandingPage"
import TeacherDashboardPage from "./components/Dashboards/Teacher/TeacherDashboard"
import StudentDashboardPage from "./components/Dashboards/Student/StudentDashboard"
import DistrictDashboardPage from "./components/Dashboards/District/District"
import LTIAssignmentPage from "./components/LTI/Assignment"
import LTIGradingPage from "./components/LTI/Grading"
import LTILessonSearchPage from "./components/LTI/LessonSearch"
import SearchByPage from "./components/searchBy/SearchByPage"
import HelpPage from "./components/resources/HelpPage"

import "./scss/app.scss"
import "./scss/common.scss"
import '../node_modules/@stem-sims/nexus/src/styles/reactTable.scss'
import "react-toastify/dist/ReactToastify.css"
import 'react-loading-skeleton/dist/skeleton.css'
import LessonModule from "components/Lessons/LessonModule/LessonModule"
import Login from "components/Login/LoginPage"
import Signup from "components/Signup/SignupPage"
import { AuthContext } from "AuthContext"
import RequestQuote from "components/RequestQuote/RequestQuote"
import AssessmentModule from "components/Assignment/AssessmentModule"
import useBoolean from "helpers/useBoolean"
import OnboardingQuiz from "components/Quiz/OnboardingQuiz"
import Subscribe from "components/Subscribe/Subscribe"
import AccountRecover from "components/Account/AccountRecover"
import ResetPassword from "components/Account/ResetPassword"
import ModulePage from "components/Module/ModulePage"
import StudentRecords from "components/Dashboards/Teacher/StudentRecords/StudentRecords"
import AdminPage from "components/Admin/AdminPage"
import ThankYouPage from "components/ThankYou/thanks"
import HomeSchoolPage from "components/Landing/Homeschool"
import BookPage from "components/Curriculum/BookPage"
import AuthRoute from "components/Auth/AuthRoute"
import EditingDashboard from "components/Editing/EditingDashboard"
import SectionPreview from "components/Editing/SectionPreview"
import PrintCodes from "components/Dashboards/Teacher/PrintCodes"
import PrintLoginInstructions from "components/Dashboards/Teacher/PrintLoginInstructions"


function App() {
  const [refresh, toggleRefresh] = useBoolean(false)
  const [authInformation, setAuthInformation] = React.useState<auth.InformationReturn>(null)
  const [loadingAuth, setLoadingAuth] = React.useState<boolean>(true)

  ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID)
  ReactPixel.pageView()

  React.useEffect(() => {
    setLoadingAuth(true)
    auth.information().then((response) => {
      setAuthInformation({...response, triggerAuthRefresh: toggleRefresh })
      setLoadingAuth(false)
    })
  }, [refresh, toggleRefresh])

  if (!authInformation)
    return <></>

  return (
    <>
      <ToastContainer
        position="top-right"
        style = {{
          marginTop: "60px",
        }}
      />
      <BrowserRouter>
        <AuthContext.Provider value={{ ...authInformation, loading: loadingAuth }}>
          <Routes>
            <Route
              path="/lti/lesson-assignment"
              element={<LTIAssignmentPage />}
            />

            <Route
              path="/lti/lesson-grading/*"
              element={<LTIGradingPage />}
            />

            <Route
              path="/lti/lesson-search"
              element={<LTILessonSearchPage />}
            />
            <Route
              path="/account/sign-up"
              element={
                <WebAppRoute auth={authInformation} route={<Signup />} />
              }
            />
            <Route
              path="/account/log-in"
              element={
                <WebsiteRoute auth={authInformation} route={<Login />} />
              }
            />
            <Route
              path="/account/recover"
              element={
                <WebsiteRoute auth={authInformation} route={<AccountRecover />} />
              }
            />
            <Route
              path="/account/password/reset"
              element={
                <WebsiteRoute auth={authInformation} route={<ResetPassword />} />
              }
            />

            {/* Using /dashboard/teacher/* is not great because 404's don't get propagated up */}
            <Route
              path="/dashboard/teacher/*"
              element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <TeacherDashboardPage />
                  }
                />
              }
            />
            {/* Using /dashboard/student/* is not great because 404's don't get propagated up */}
            <Route
              path="/dashboard/student/*"
              element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <StudentDashboardPage />
                  }
                />
              }
            />
            {/* Using /dashboard/district/* is not great because 404's don't get propagated up */}
            <Route
              path="/dashboard/district/*"
              element={
                <WebAppRoute
                  auth={authInformation}
                  route={<DistrictDashboardPage />}
                />
              }
            />
            {/* Using /dashboard/teacher/* is not great because 404's don't get propagated up */}
            <Route
            path="/dashboard/teacher/*"
            element={
              <WebsiteRoute
                auth={authInformation}
                route={
                  <TeacherDashboardPage />
                }
              />
            }
            />

            {/* Using /dashboard/student/* is not great because 404's don't get propagated up */}
            <Route
            path="/dashboard/student/*"
            element={
              <WebsiteRoute
                auth={authInformation}
                route={
                  <StudentDashboardPage />
                }
              />
            }
            />

            {/* Using /dashboard/district/* is not great because 404's don't get propagated up */}
            <Route
            path="/dashboard/district/*"
            element={
              <WebsiteRoute
                auth={authInformation}
                route={
                  <DistrictDashboardPage />
                }
              />
            }
            />

            <Route
            path="/simulations/pure-inquiry/"
            element={
                <WebsiteRoute
                  auth={authInformation}
                  route={
                    <PureInquiryPage trackingEnabled={authInformation.trackingEnabled} />
                  }
                />
              }
            />

            <Route
            path="/simulations/lesson/*"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <LessonModule authInfo={authInformation} />
                  }
                />
              }
            />
            <Route
            path="/lessons/search"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <SearchByPage />
                  }
                />
              }
            />

            <Route
              path="/request-quote"
              element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <RequestQuote />
                  }
                  />
                }
              />

            <Route
            path="/subscribe"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <Subscribe />
                  }
                />
              }
            />
            <Route path="/help" element={<WebAppRoute auth={authInformation} route={<HelpPage />} />} />
            <Route path="/" element={<WebsiteRoute auth={authInformation} route={<LandingPage />} />} />
            <Route path="*" element={<WebsiteRoute auth={authInformation} route={<NotFoundPage />} />} />
            <Route
            path="/simulations/assessment"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <AssessmentModule />
                  }
                />
              }
            />
            <Route
            path="/simulations/moduleName=:moduleName/tab=:tab"
            element={
                <WebsiteRoute
                  auth={authInformation}
                  route={<ModulePage />}/>
                } 
            />
            <Route
            path="/onboarding/quiz"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <OnboardingQuiz />
                  }
                />
              }
            />
            <Route
            path="/admin"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <AdminPage />
                  }
                />
              }
            />
            <Route
            path="/student/records/:studentId/:studentName"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <StudentRecords />
                  }
                />
              }
            />
            <Route
            path="/homeschool"
            element={
                <HomeSchoolPage />
              }
            />
            <Route
            path="/thank-you/:productName/:quantity"
            element={
                <WebAppRoute
                  auth={authInformation}
                  route={
                    <ThankYouPage />
                  }
                />
              }
            />
            <Route
              path="/books/*"
              element={
                <WebAppRoute
                  auth={authInformation}
                  route={<BookPage />}
                />
              }
            />

            <Route
              path="/editing/*"
              element={
                <AuthRoute
                  permission="EDITING"
                >
                  <WebAppRoute auth={authInformation} route={
                    <EditingDashboard />
                  } />
                </AuthRoute>
              }
            />

            <Route path="/curriculum/section-preview/:topicId/:sectionId" element={
              <AuthRoute
                permission="EDITING"
              >
                <SectionPreview />
              </AuthRoute>
            } />

            <Route path="/printable-student-codes" element={
              <AuthRoute
                permission="TEACHER"
              >
                <PrintCodes />
              </AuthRoute>
            } />

            <Route path="/printable-student-login-instructions" element={
              <AuthRoute
                permission="TEACHER"
              >
                <PrintLoginInstructions />
              </AuthRoute>
            } />
          </Routes>
        </AuthContext.Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
