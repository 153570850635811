import { ExcalidrawImageElement, FileId } from "@excalidraw/excalidraw/types/element/types"
import { DataURL } from "@excalidraw/excalidraw/types/types"
import { Section } from "shared/lessons"

const SCALE_DOWN_BREAK_POINT = 1200

export const convertBlobToBase64 = async (blob: Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = async function () {
            resolve(reader.result)
        }
    })
}

export const getImgDimensions = (base64String) : Promise<{ width: number; height: number }> => {
  const scale = window.innerWidth <= SCALE_DOWN_BREAK_POINT ? window.innerWidth / SCALE_DOWN_BREAK_POINT : 1
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve({ width: Math.min(img.width, 700 * scale), height: Math.min(img.height, 400 * scale) })
      }
      img.src = base64String
    })
}

export const loadImage = async ({ initialDrawingData, imagePath, fileId, imageId }: { initialDrawingData?: string | { answer: string, drawingResponse: string }, imagePath: string, fileId: string, imageId: string }) => {
  let backgroundResponse = null
  let drawingDataResponse = null
  let responseUrl: string, savedState: string

  if (typeof initialDrawingData === "string") {
    responseUrl = initialDrawingData
  } else {
    responseUrl = initialDrawingData?.drawingResponse
    savedState = initialDrawingData?.answer
  }

  if (!savedState && responseUrl) {
    drawingDataResponse = await fetch(responseUrl)
  }

  if (imagePath) {
    backgroundResponse = await fetch(imagePath)
  }

  let backgroundBlob: Blob = null
  let backgroundType = null
  let drawingDataBlob: Blob = null
  let drawingDataType = null

  if (backgroundResponse?.ok) {
    backgroundBlob = await backgroundResponse.blob()
    backgroundType = backgroundBlob.type
  }

  if (drawingDataResponse?.ok) {
    drawingDataBlob = await drawingDataResponse.blob()
    drawingDataType = drawingDataBlob.type
  }

  const backgroundBase64 = backgroundBlob ? await convertBlobToBase64(backgroundBlob) : null
  const backgroundDimensions = backgroundBase64 ? await getImgDimensions(backgroundBase64) : null
  const drawingDataBase64 = drawingDataBlob ? await convertBlobToBase64(drawingDataBlob) : null
  const drawingDimensions = drawingDataBase64 ? await getImgDimensions(drawingDataBase64) : null

  let elements = []
  let state: Record<string, unknown>
  const files = {}

  if (savedState) {
    const savedDrawingData: { elements: unknown[], state: Record<string, unknown> } = JSON.parse(savedState)
    elements = savedDrawingData.elements
    state = savedDrawingData.state
    if (state) {
      state.collaborators = new Map(Object.entries(savedDrawingData.state.collaborators ?? {}))
    }
  }


  if (backgroundBase64 && backgroundDimensions) {
    let backgroundElement: ExcalidrawImageElement = elements.find((element) => element.fileId === `background-${fileId}`)
    if (!backgroundElement) {
      backgroundElement = {
        type: "image",
        id: `background-${imageId}`,
        status: "saved",
        fileId: `background-${fileId}` as FileId,
        version: 2,
        versionNonce: Date.now(),
        x: 800,
        y: 800,
        width: backgroundDimensions.width,
        height: backgroundDimensions.height,
        scale: [1, 1],
        isDeleted: false,
        fillStyle: "hachure",
        strokeWidth: 1,
        strokeStyle: "solid",
        roughness: 1,
        opacity: 100,
        groupIds: [],
        strokeColor: "#000000",
        backgroundColor: "transparent",
        seed: Date.now(),
        roundness: null,
        angle: 0,
        frameId: null,
        boundElements: null,
        updated: 1,
        locked: true,
        link: null,
      }
      elements.unshift(backgroundElement)
    }
    files[backgroundElement.fileId] = {
      mimeType: backgroundType,
      id: backgroundElement.fileId,
      dataURL: backgroundBase64 as DataURL,
      created: Date.now(),
    }
  }

  if (drawingDataBase64 && drawingDimensions) {
    const drawingElement: ExcalidrawImageElement = {
      type: "image",
      id: `drawing-${imageId}`,
      status: "saved",
      fileId: `drawing-${fileId}` as FileId,
      version: 2,
      versionNonce: Date.now(),
      x: 800,
      y: 800,
      width: drawingDimensions.width,
      height: drawingDimensions.height,
      scale: [1, 1],
      isDeleted: false,
      fillStyle: "hachure",
      strokeWidth: 1,
      strokeStyle: "solid",
      roughness: 1,
      opacity: 100,
      groupIds: [],
      strokeColor: "#000000",
      backgroundColor: "transparent",
      seed: Date.now(),
      roundness: null,
      angle: 0,
      frameId: null,
      boundElements: null,
      updated: 1,
      locked: false,
      link: null,
    }
    elements.push(drawingElement)
    files[drawingElement.fileId] = {
      mimeType: drawingDataType,
      id: drawingElement.fileId,
      dataURL: drawingDataBase64 as DataURL,
      created: Date.now(),
    }
  }

  return {
    elements: elements,
    scrollToContent: true,
    files: files,
    appState: state,
  }
}

/**
 * 
 * Creates an object with keys for each drawing lesson question in the format 'question-:sectionNumber-:questionNumber' and assign null to each key
 */
export const initLessonDrawingResponses = async (sections: Section[]) => {
  const data = {}
  sections.forEach((section, sectNumber) => {
    section?.content.forEach(task => {
      if (task.category === "Question" && task.isDrawing === "Yes") {
        data[`question-${sectNumber + 1}-${task.index}`] = null
      }
    })
  })
  return data
}

/**
 * 
 * Load the initial lesson drawing data from previous attempt
 */
export const loadLessonDrawingData = async (initialDrawingData: any, data: any) => {
  if (!initialDrawingData) return data
  const res = {}
  const promises = Object.keys(data).map(async key => {
    if (!initialDrawingData[key]) return res[key] = null
    const response = await fetch(initialDrawingData[key])
    if (!response.ok) return
    const blob = await response.blob()
    const base64String = await convertBlobToBase64(blob)
    res[key] = base64String
  })
  await Promise.all(promises)
  return res
}

/**
 * 
 * Convert an AWS S3 drawing image url to a base64 string 
 */
export const convertImgToBase64 = async (url: string) => {
  const response = await fetch(url)
  if (!response.ok) return
  const blob = await response.blob()
  const base64String = await convertBlobToBase64(blob)
  return base64String
}
