import { Excalidraw, exportToBlob } from "@excalidraw/excalidraw"
import { useContext, useEffect, useRef, useState } from "react"
import { ExcalidrawImperativeAPI, ExcalidrawInitialDataState } from "@excalidraw/excalidraw/types/types"
import Spinner from 'react-bootstrap/Spinner'
import styles from "./DrawingQuestion.module.scss"
import { Question } from "shared/lessons"
import CustomHTML from "components/CustomHTML"
import { convertBlobToBase64, loadImage } from "../helpers/drawingHelper"
import { LessonContext } from "components/Lessons/LessonContext"
import { CurriculumQuestion } from "shared/types/curriculumTypes"
import { AssignmentTopicContext } from "../AssignmentTopicContext"

interface Props {
  questionType: "lesson" | "assessment" | "topic"

  // lesson drawing question props
  lessonQuestionIndex?: string
  lessonQuestion?: Question
  sectionNumber?: number // also used for topic
  autoSave?: () => void
  minimizeCanvas?: () => void

  // assessment drawing question props
  questionNumber?: number
  questionImage?: string
  initialAssessmentDrawingData?: string
  setAssessmentDrawingResponse?: React.Dispatch<React.SetStateAction<{}>>

  // topic drawing question props
  topicQuestion?: CurriculumQuestion
  handleAnswerChange?: (sectionNum: number, questionsNum: number, answer: string, drawingResponse: string) => void
  initialTopicDrawingData?: { drawingResponse: string, answer: string }
}

const DrawingQuestion = ({ questionType, initialAssessmentDrawingData, setAssessmentDrawingResponse, lessonQuestionIndex, lessonQuestion, sectionNumber, autoSave, minimizeCanvas, questionImage, questionNumber, topicQuestion, handleAnswerChange, initialTopicDrawingData } : Props) => {
  const [excaliAPI, setExcaliAPI] = useState<ExcalidrawImperativeAPI>(null)
  const [saving, setSaving] = useState(false)
  const [initialData, setInitialData] = useState<ExcalidrawInitialDataState>(null)
  const [hasStartedDrawing, setHasStartedDrawing] = useState<boolean>(false)

  const { drawingResponses, setDrawingResponses, isPartOfTopic } = useContext(LessonContext) // for standalone lesson
  const { lessonDrawResponses, setLessonDrawResponses } = useContext(AssignmentTopicContext) // for embedded lesson

  const save = () => {
    setSaving(true)
    // add a delay for the excali api to get latest scene elements
    setTimeout(() => {
      saveDrawing()
      .then(() => setTimeout(() => setSaving(false), 3000))
    }, 100)
  }

  const keySaveRef = useRef(null)

  const keySave = () => {
    if (keySaveRef.current) {
      clearTimeout(keySaveRef.current)
    }
    const timeoutId = setTimeout(() => {
      if (keySaveRef.current === timeoutId) {
        save()
      }
    }, 500)
    keySaveRef.current = timeoutId
  }


  const saveDrawing = async () => {
    if (!excaliAPI) return

    const elements = excaliAPI.getSceneElements()
    const excaliState = {
      elements: elements,
      state: excaliAPI.getAppState()
    }
    let blob: Blob = null

    if (elements.length > 0) {
      blob = await exportToBlob({
        elements: elements,
        mimeType: "image/png",
        appState: {
          ...excaliAPI.getAppState,
        },
        files: excaliAPI?.getFiles()
      })
    }

    const base64String = blob ? await convertBlobToBase64(blob) : null
    if (questionType === "lesson") {
      if (isPartOfTopic) {
        setLessonDrawResponses(prev => ({...prev, [`question-${sectionNumber}-${lessonQuestion.index}`]: base64String}))
      } else {
        setDrawingResponses(prev => ({...prev, [`question-${sectionNumber}-${lessonQuestion.index}`]: base64String}))
      }
      autoSave?.()
    } else if (questionType === "assessment") {
      setAssessmentDrawingResponse(prev => ({...prev, [`question-${questionNumber}`]: base64String}))
    } else {
      handleAnswerChange(sectionNumber, topicQuestion?.sortOrder, JSON.stringify(excaliState), base64String as string)
      autoSave()
    }
  }

  /**
   * Load initial drawing image (if resuming previous attempt) instead of original lesson question image
   */
  useEffect(() => {
    const fileId = `file-${questionType}-${lessonQuestion?.index ?? questionNumber ?? topicQuestion?.sortOrder}`
    const imageId = `image-${questionType}-${lessonQuestion?.index ?? questionNumber ?? topicQuestion?.sortOrder}`
    if (questionType === "lesson") {
      const initialDrawingData = isPartOfTopic ? lessonDrawResponses?.[`question-${sectionNumber}-${lessonQuestion.index}`] : drawingResponses?.[`question-${sectionNumber}-${lessonQuestion.index}`]
      loadImage({ initialDrawingData: initialDrawingData, imagePath: lessonQuestion.imagePath, fileId, imageId }).then(res => {
        setInitialData(res)
      })
    } else if (questionType === "assessment") {
      loadImage({ initialDrawingData: initialAssessmentDrawingData ?? null, imagePath: questionImage, fileId, imageId }).then(res => {
        setInitialData(res)
      })
    } else {
      if (topicQuestion?.questionImageId && !topicQuestion?.questionImageUrl) return // this ensures topic question image url is loaded properly
      loadImage({ initialDrawingData: initialTopicDrawingData, imagePath: topicQuestion.questionImageUrl, fileId, imageId }).then(res => {
        setInitialData(res)
      })
    }

    return () => {
      setInitialData(null)
    }
    // eslint-disable-next-line
  }, [lessonQuestion?.imagePath, lessonQuestion?.index, questionType, sectionNumber, questionImage, topicQuestion])

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll<HTMLElement>("button[data-testid='main-menu-trigger']").forEach((item) => item.style.display = "none")
    }, 1000)
  }, [])


  if (initialData === null) return <></>

  return (
    <>
        {questionType === "lesson" && 
          <div className="d-flex flex-row align-items-center justify-content-between">
            <span>{lessonQuestionIndex}. <CustomHTML html={lessonQuestion.content} /></span>
            <i role="button" className={`fas fa-close iconButton me-2 py-1 text-dark`} onClick={minimizeCanvas}/>
          </div>}
        <div 
          className={styles.canvas}
          onClick={(e: any) => {
            const target = e.target.closest("button")
            if (target && target.tagName === "BUTTON") {
              const fillActions = ["fill-solid", "fill-cross-hatch", "fill-hachure"]
              if (fillActions.includes(target.getAttribute("data-testid"))) {
                e.preventDefault()
              }
            }
          }}
          onKeyDownCapture={(e) => {
            keySave()
          }}
          onWheelCapture={e => e.stopPropagation()} // prevent scrolling
          >
            {saving && <span className={styles.loading}>
              <span className="me-2">Saving</span>
                <Spinner animation="border" role="status" size="sm">
                </Spinner>
            </span>}
            <Excalidraw
              initialData={initialData}
              onPointerDown={() => setHasStartedDrawing(true)}
              onPointerUpdate={(e) => {
                if (e.button === "up" && hasStartedDrawing) {
                  save()
                  setHasStartedDrawing(false)
                }
              }}
              autoFocus={false}
              excalidrawAPI={api => setExcaliAPI(api)}
              zenModeEnabled={false}
              viewModeEnabled={false}
              UIOptions={{
                tools: { image: false }
              }} />
        </div>
        <div className="m-auto d-flex justify-content-center mt-2 p-3">
        <div className="text-muted">The drawing will be saved automatically as you draw on the canvas.</div>
        </div>
    </>
  )
}

export default DrawingQuestion
