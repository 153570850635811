import { ButtonGroup, ToggleButton } from "react-bootstrap"
import styles from "../../forms.module.scss"
import { CopyOption } from "shared/types/teacherTypes"

interface Props {
    onClassChosen: any
    onLessonPlanChosen: any
    copyOption: CopyOption
}

const CopyOptionToggle = ({ onClassChosen, onLessonPlanChosen, copyOption } : Props) => {
  return (
    <ButtonGroup className="d-flex justify-content-center">
        <ToggleButton
            value="class"
            type="radio"
            className={`fw-bold ${copyOption === "class" ? styles.termBgSelect : styles.termBgUnselect} border-0`}
            onClick={onClassChosen}
        >Class</ToggleButton>

        <ToggleButton
            value="students"
            type="radio"
            className={`fw-bold ${copyOption === "lesson-plan" ? styles.termBgSelect : styles.termBgUnselect} border-0`}
            onClick={onLessonPlanChosen}
        >Lesson Plan</ToggleButton>
    </ButtonGroup>
  )
}

export default CopyOptionToggle
