import { Dispatch, SetStateAction, createContext } from "react"
import { LessonResponse } from "shared/lessons"

type LessonContextType = {
    lesson: LessonResponse,
    questionAnswersRef: any,
    tableAnswersRef: any,
    drawingResponses?: any,
    setDrawingResponses?: any
    prevSavedRef: any,
    startedRef: any,
    lessonSubmissionIdRef: any,
    refreshLesson: () => Promise<void>
    refreshToken: any
    submitLesson: (form:any) => Promise<boolean>,
    setQuestionAnswers: Dispatch<SetStateAction<any[]>>,
    setTableAnswers: Dispatch<SetStateAction<any[]>>,
    containerType?: "assignment" | "module",
    isPartOfTopic: boolean
}

type LessonBodyContextType = {
    checkpoint: number
    setCheckpoint: (checkpoint: number) => void
    lessonPath: string
    refreshSections: () => void
    sections: any[]
    setSections: (sections: any[]) => void
}

export const LessonContext = createContext<LessonContextType>({
    lesson: null,
    questionAnswersRef: null,
    tableAnswersRef: null,
    prevSavedRef: null,
    startedRef: null,
    lessonSubmissionIdRef: null,
    submitLesson: () => null,
    refreshLesson: () => null,
    setQuestionAnswers: () => null,
    setTableAnswers: () => null,
    refreshToken: null,
    isPartOfTopic: false
})

export const LessonBodyContext = createContext<LessonBodyContextType>({
    checkpoint: 0,
    setCheckpoint: (checkpoint) => {},
    lessonPath: "",
    refreshSections: () => null,
    sections: [],
    setSections: (sections) => {},
})
