import { useModel } from "@stem-sims/nexus"
import { Button, Col, Modal, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { PulseLoader } from "react-spinners"
import { getTopic, updateSection } from "shared/routes/curriculum/books"
import { useEffect, useMemo, useState } from "react"
import { CurriculumSectionTypes } from "shared/types/curriculumTypes"
import React from "react"
import EditingItemPane, { FieldType } from "./EditingItemPane"
import titleCase from "helpers/titleCase"
import Search from "components/Lessons/Search/Root"
import assessments from "shared/routes/assessments/assessments"
import lessons, { LessonResponse } from "shared/lessons"
import EditingSectionContent from "./EditingSectionContent"
import EditingSectionQuestions from "./EditingSectionQuestions"
import SectionPreviewPane from "./SectionPreviewPane"

import styles from "./editingStyles.module.scss"

const { scrollableOverflow } = styles

export default function EditingSection() {


    const [selectModal, setSelectModal] = useState(null)

    const { topicId, sectionId } = useParams()

    const { loading: topicLoading, response: topic, refreshModel } = useModel({
        model: getTopic,
        props: { topicId }
    })

    const section = topic?.sections.find((section) => section.id === sectionId)

    const { response: assessment } = useModel({
        model: assessments.findById,
        props: { id: section?.assessmentId ?? undefined },
    })

    const [lesson, setLesson] = useState<LessonResponse>(null)

    useEffect(() => {
        if (!section?.lessonId) {
            setLesson(null)
        } else {
            lessons.findById(section.lessonId).then((lesson) => {
                setLesson(lesson)
            })
        }
    }, [section?.lessonId])


    const onSubmitCallback = React.useCallback(async (values) => {
        await updateSection({
            sectionId: section.id,
            header: values.header,
            sectionText: values.sectionText,
            type: values.type,
            lessonId: values.lessonId ?? undefined,
            assessmentId: values.assessmentId ?? undefined,
            graded: values.graded,
        })
        refreshModel()
    }, [section?.id, refreshModel])

    const fields = useMemo(() => {
        const fields: FieldType[] = [
            { fieldName: "header", inputType: "input" },
            { fieldName: "sectionText", inputType: "textarea" },
            { fieldName: "type", inputType: "select", options: CurriculumSectionTypes },
            { fieldName: "graded", inputType: "checkbox" },
        ]

        if (section?.type === "Assessment") {
            fields.push({
                fieldName: "assessment", inputType: "special", render: () => (<>
                    {assessment && <span> Selected: {assessment.module} - {assessment.type} - #{assessment.number}</span>}
                    {!assessment && <span style={{ color: "red" }}> Not Selected</span>}
                    <br />
                    <Button variant="theme" onClick={() => { setSelectModal("assessment") }}>
                        Select Assessment
                    </Button>
                </>)
            })
        }


        if (section?.type === "Lesson") {
            fields.push({
                fieldName: "lesson", inputType: "special", render: () => (<>
                    {lesson && <span> Selected: {lesson.moduleName} - {lesson.type} - #{lesson.number} ({lesson.title})</span>}
                    {!lesson && <span style={{ color: "red" }}> Not Selected</span>}
                    <br />
                    <Button variant="theme" onClick={() => { setSelectModal("lesson") }}>
                        Select Lesson
                    </Button>
                </>)
            })
        }

        return fields
    }, [section?.type, assessment, lesson])

    const [sidePane, setSidePane] = useState<"preview" | "subitems">("subitems")

    useEffect(() => {
        if (["HTML", "Questions"].includes(section?.type)) {
            setSidePane("subitems")
        } else {
            setSidePane("preview")
        }
    }, [section?.type])


    if (!topic && topicLoading) {
        return <>
            <PulseLoader />
        </>
    }

    return <>
        <h3>Section {section.sortOrder}: {section.header}</h3>
        <Row>
            <Col className={sidePane === "preview" ? scrollableOverflow : ""}>
                <EditingItemPane
                    data={section}
                    fields={fields}
                    itemTypeName="section"
                    onSubmit={onSubmitCallback}
                />

                {sidePane === "preview" && <>
                    {section.type === "HTML" && <>
                        <EditingSectionContent
                            loading={topicLoading}
                            refreshModel={refreshModel}
                            section={section}
                        />
                    </>}

                    {section.type === "Questions" && <>
                        <EditingSectionQuestions
                            loading={topicLoading}
                            refreshModel={refreshModel}
                            section={section}
                        />
                    </>}
                </>}
            </Col>

            <Col  className={sidePane === "preview" ? scrollableOverflow : ""}>

                {["HTML", "Questions"].includes(section?.type) && <>
                    <Row>
                        <Col>
                            <Button variant="theme" onClick={() => setSidePane("preview")}>Preview Section</Button>
                        </Col>

                        <Col>
                            <Button variant="theme" onClick={() => {
                                setSidePane("subitems")
                                window.open(`/curriculum/section-preview/${topicId}/${section.id}`, "_blank")
                            }}>Pop Out Preview</Button>
                        </Col>

                        <Col>
                            <Button variant="theme" onClick={() => setSidePane("subitems")}>Edit {section.type === "HTML" ? "Content" : "Questions"}</Button>
                        </Col>
                    </Row>
                </>}

                {sidePane === "preview" && <>
                    <SectionPreviewPane
                        section={section}
                    />
                </>}

                {sidePane === "subitems" && <>
                    {section.type === "HTML" && <>
                        <EditingSectionContent
                            loading={topicLoading}
                            refreshModel={refreshModel}
                            section={section}
                        />
                    </>}

                    {section.type === "Questions" && <>
                        <EditingSectionQuestions
                            loading={topicLoading}
                            refreshModel={refreshModel}
                            section={section}
                        />
                    </>}
                </>}

            </Col>


        </Row>

        <Modal size="xl" show={selectModal} onHide={() => {
            setSelectModal(null)
        }}>
            <Modal.Header closeButton>
                Select {titleCase(selectModal)}
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Search
                        onLessonSelection={(lesson) => {
                            if (section?.type === "Assessment") {
                                return <>
                                    <Button
                                        onClick={async () => {
                                            await updateSection({
                                                sectionId: section?.id,
                                                assessmentId: lesson?.assessment?.id,
                                            })
                                            refreshModel()
                                            setSelectModal(null)
                                        }}
                                    >Select Assessment</Button>
                                </>
                            } else if (section?.type === "Lesson") {
                                return <>
                                    <Button onClick={async () => {
                                        await updateSection({
                                            sectionId: section?.id,
                                            lessonId: lesson?.id,
                                        })
                                        refreshModel()
                                        setSelectModal(null)
                                    }}>Select Lesson</Button>
                                </>
                            }
                        }}
                        viewType="landing"
                    />
                </Row>
            </Modal.Body>

            <Modal.Footer>
            </Modal.Footer>
        </Modal>

    </>
}

